::backdrop
{
    background-color: white;
}

.wrapper {
    margin: 50px;
    padding: 10px;
}

.fl{
    float: left;
}

.backdrop-gray{
    background-color: azure;
}
.card-modern{
    border-radius: 20px;
    box-shadow: 0 2px 12px 0 rgba(250, 248, 248, 0.16);
}

.order-card {
    border-radius: 10;
}

.order-card-header{
    font-size: 13px;
    margin: 5px
}

.order-done{
    background-color: aquamarine;
    border: solid 2px aquamarine;
}
.order-pending{
    background-color: rgb(243, 205, 159);
    border: solid 2px rgb(243, 205, 159);
}
.order-preparing{
    background-color: rgb(243, 205, 159);
    border: solid 2px rgb(243, 205, 159);
}
.order-new{
    background-color: rgb(212, 144, 144);
    border: solid 2px rgb(212, 144, 144);
}
.content {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.content::-webkit-scrollbar {
    display: none;
}

.overlay-topLeft{
    position: fixed;
    cursor: pointer;
    top: 1%;
    left: 1%;
}

.kitchen-header{
    font-size: 20;
    font-weight: 500;
    color: black
}

.overlay-topCenter{
    position: fixed;
    cursor: pointer;
    top: 10%;
    left: 50%;
}

.overlay-topRight{
    position: fixed;
    cursor: pointer;
    top: 1%;
    right: 2%
}

.overlay-bottomLeft{
    position: fixed;
    cursor: pointer;
    bottom: 5%;
    left: 2%;
}

.overlay-bottomCenter{
    position: fixed;
    cursor: pointer;
    bottom: 10%;
    left: 50%;
}

.overlay-bottomRight{
    position: fixed;
    cursor: pointer;
    bottom: 5%;
    right: 2%
}

.icon-xs {
    font-size: 10;
}

.icon-sm {
    font-size: 15;
}

.icon-md {
    font-size: 25;
}

.icon-lg {
    font-size: 30;
}